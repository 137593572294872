
import { Vue, Component, Watch } from 'vue-property-decorator';
import { getPointHistoryList } from '@/api/pointHistory';
import Pagination from '@/components/Pagination/index.vue';

@Component({
  components: {
    Pagination,
  },
})
export default class extends Vue {
  mounted() {
    this.getHistoryList();
  }

  private loading = true;

  private historyList: any = [];

  private listQuery = {
    page: 1,
    size: 10,
  }

  private totalElements = 0;

  private getHistoryList() {
    this.loading = true;
    getPointHistoryList(this.listQuery).then((res) => {
      this.historyList = res.data.content;
      this.totalElements = res.data.totalElements;
      this.loading = false;
    });
  }
}
